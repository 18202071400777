import React from 'react';
import Scroll from '../common/Scroll';
import Scheduler from '../common/scheduler';
const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div id="fixedContainer">
    <div>
      Same day service<br/>
      <span className={'pricing'} style={{fontSize: '40px'}}><b>$1.89/lb</b></span>
    </div>
    </div>
    <Scheduler />
  </section>


export default Introduction;
